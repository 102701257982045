/** @jsxImportSource @emotion/react */
import React from "react";
import { MDXProvider } from "@mdx-js/react";
import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// Define styled components for various Markdown elements
const StyledMarkdownContainer = styled.div`
  font-family: "Roboto", sans-serif;
  color: #333;
  padding: 0 25px;
  margin: 0 auto;
  max-width: 800px;
  white-space: pre-wrap;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #2c3e50;
    // margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.875rem;
  }

  p,
  ul,
  ol {
    margin: 0.5em 0;
    font-size: 1rem;
    // line-height: 1.6;
  }
  ul,
  ol {
    padding-left: 1.5rem;
  }
  li {
    margin-bottom: 0.25rem;
  }

  a {
    color: #3498db;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  code {
    background: #f5f5f5;
    color: #7f8c8d;
    border-radius: 4px;
    font-family: "Source Code Pro", monospace;
    padding: 2px 4px;
  }

  pre {
    background: #f5f5f5;
    padding: 1em;
    border-radius: 4px;
    overflow-x: auto;
    code {
      background: none;
      padding: 2px 4px;
      color: #7f8c8d;
    }
  }

  blockquote {
    border-left: 4px solid #3498db;
    padding-left: 10px;
    color: #7f8c8d;
    background: #ecf0f1;
    margin: 0 0.5em;
    font-style: italic;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    border: 1px solid #ddd;
    font-size: 1rem;
    th,
    td {
      padding: 8px;
      border: 1px solid #ddd;
      text-align: left;
    }
    th {
      background: #f5f5f5;
      font-weight: bold;
    }
    tbody tr:nth-of-type(odd) {
      background: #f9f9f9;
    }
  }
`;

const components = {
  h1: (props) => <h1 {...props} />,
  h2: (props) => <h2 {...props} />,
  h3: (props) => <h3 {...props} />,
  h4: (props) => <h4 {...props} />,
  h5: (props) => <h5 {...props} />,
  h6: (props) => <h6 {...props} />,
  p: (props) => <p {...props} />,
  a: (props) => <a {...props} />,
  ul: (props) => <ul {...props} />,
  ol: (props) => <ol {...props} />,
  li: (props) => <li {...props} />,
  code: (props) => <code {...props} />,
  pre: (props) => <pre {...props} />,
  blockquote: (props) => <blockquote {...props} />,
  table: (props) => <table {...props} />,
  th: (props) => <th {...props} />,
  td: (props) => <td {...props} />,
};

const dummyMd = `**Rotate D times:**
*Write a program that reads the comma-separated integers and a positive
integer D, then rotates the numbers D times to the left, and prints the
rotated list.*

**Input:** 
>The first line of input will be comma-separated integers. The
second line of input will be a positive integer representing D.

**Output:** 
>The output should be a single line containing a rotated
list.

**Explanation:**
For example, if the given comma-separated integers are \`1, 2, 3, 4, 5\` and
the given number D = 2:
\`1 2 3 4 5\` on 1st rotation - \`2 3 4 5 1\`
\`2 3 4 5 1\` on 2nd rotation - \`3 4 5 1 2\`
After rotating the comma-separated integers two times by left, the
elements are \`3, 4, 5, 1, 2\`.
So, the output should be: \`[3, 4, 5, 1, 2]\`

---
**Sample Input 1:**
\`1,2,3,4,5\`
\`2\`
**Sample Output 1:**
\`[3, 4, 5, 1, 2]\`

**Sample Input 2:**
\`1,3,5,7,9,11\`
\`25\`
**Sample Output 2:**
\`[3,5,7,9,11,1]\``;

const RenderMarkdown = ({ assignment }) => {
  return (
    <StyledMarkdownContainer>
      <MDXProvider components={components}>
        <ReactMarkdown components={components} remarkPlugins={[remarkGfm]}>
          {assignment}
        </ReactMarkdown>
      </MDXProvider>
    </StyledMarkdownContainer>
  );
};

export { RenderMarkdown };
