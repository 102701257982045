import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@emotion/react";
import { Provider } from "react-redux";
import store from "./Redux/store";
import generateTheme from "./Theme/theme";
import { GoogleOAuthProvider } from "@react-oauth/google";

const GOOGLE_CLIENT_ID = `282788108996-gpe21a60oa8f5ge14um09kgtfmrjfpj1.apps.googleusercontent.com`;
// Example primary and secondary colors

const primaryMain = "#094b71";
const secondaryMain = "#0217cf";

// Generate the theme using the generateTheme function
const theme = generateTheme(primaryMain, secondaryMain);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
      {/* </React.StrictMode> */}
    </Provider>
  </ThemeProvider>
);
