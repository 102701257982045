import React from "react";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";

const DomainCard = (props) => {
  const { domain } = props;

  return (
    <Card
      // onClick={() => handleClick(id)}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "card.main",
        padding: 2,
        transition: "all 0.3s ease",
        cursor: "pointer",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: "cardhover.main",
          transform: "scale(1.02)",
          ".card-img": {
            opacity: 1,
          },
        },
      }}
    >
      <Grid container>
        <Grid item xs={4} m="auto">
          <CardMedia
            className="card-img"
            component="img"
            sx={{
              width: 100,
              height: 100,
              margin: "auto",
              opacity: 0.4,

              // ":hover": { backgroundColor: "cardhover.main" },
            }}
            image={domain.img_url}
            alt="broken"
          />
        </Grid>
        <Grid item xs={8}>
          <CardContent>
            <Typography
              component="div"
              variant="body"
              sx={{
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              {domain.name}
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DomainCard;
