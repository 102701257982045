import instance from "./axiosURL";

const authUrl = "/api/auth";
const postsUrl = "/api/posts";
const quizzesUrl = "/api/quiz";
const projectsUrl = "/api/projects";
const domainsUrl = "/api/domains";

//#region Auth Routes
// Authentication routes
export const login = (data) => {
  return instance.post(`${authUrl}/login`, data);
};
export const register = (data) => {
  return instance.post(`${authUrl}/signup`, data);
};
export const Google_login = (data) => {
  return instance.post(`${authUrl}/google-login`, data);
};
export const ResetPassword = (data) => {
  return instance.post(`${authUrl}/reset-password`, data);
};
export const logout = () => {
  localStorage.removeItem(`access_token`);
  localStorage.removeItem(`userData`);
};

//#endregion

// --------------------------Protected routes------------------------------

//#region Posts Routes
// ---------Posts routes---------
export const getAllPosts = () => {
  return instance.get(`${postsUrl}/all`);
};

export const getPopularPosts = () => {
  return instance.get(`${postsUrl}/popular-articles`);
};

export const getPost = (PostId) => {
  return instance.get(`${postsUrl}/${PostId}`);
};

//#endregion

//#region Quiz Routes
// ---------Quiz routes---------
export const getLanguages = () => {
  return instance.get(`${quizzesUrl}/quiz-selection`);
};

export const getQuiz = (languageId, subjectId, levelId) => {
  return instance.get(`${quizzesUrl}/${languageId}/${subjectId}/${levelId}`);
};

export const getQuizQuestions = (startQuizIdUrl) => {
  return instance.get(`${quizzesUrl}/${startQuizIdUrl}`);
};

export const verifyQuiz = (data) => {
  return instance.post(`${quizzesUrl}/verify`, data);
};

//#endregion

//#region Projects Routes
// ---------Projects routes---------

export const getAllProjects = () => {
  return instance.get(`${projectsUrl}/all`);
};
export const getProject = (ProjectId) => {
  return instance.get(`${projectsUrl}/${ProjectId}`);
};

//#endregion

//#region Domains Routes
// ---------Domains routes---------
export const getDomains = () => {
  return instance.get(`${domainsUrl}/`);
};
export const getDomainSpecificPosts = (DomainId) => {
  return instance.get(`${domainsUrl}/${DomainId}`);
};

//#endregion
