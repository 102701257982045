import { ProjectsActionType } from "../constants/projects-constant";

const initialState = {
  projects: [],
  isLoading: false,
  error: null,
};
export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProjectsActionType.PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ProjectsActionType.PROJECT_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        isLoading: false,
      };
    case ProjectsActionType.PROJECT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
