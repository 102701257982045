import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../Components/common/Loader";
import DomainCard from "../../Components/cards/DomainCard";
import LandingPageImage from "../../Assets/LandingPageImage.svg";
import ContentSection from "../../Components/common/ContentSection";
import { HeroSection } from "../../Components/common/HeroSection";
import { FeaturePostCard } from "../../Components/cards/FeaturePostCard";
import { fetchAllDomains } from "./redux/actions/domainActions";
import { fetchPopularPosts } from "./redux/actions/popularPostsActions";
import { fetchAllPosts } from "../Articles-Page/redux/actions/postsActions";

const LandingPage = () => {
  const dispatch = useDispatch();

  const { domains, isLoading: isDomainsLoading } = useSelector(
    (state) => state.domains
  );
  const { popularPosts, isLoading: isPopularPostsLoading } = useSelector(
    (state) => state.popularPosts
  );
  const { posts } = useSelector((state) => state.posts);

  useEffect(() => {
    if (!domains.length) {
      dispatch(fetchAllDomains());
    }
    if (!popularPosts.length) {
      dispatch(fetchPopularPosts());
    }
    if (!posts.length) {
      dispatch(fetchAllPosts());
    }
  }, [dispatch, domains.length, popularPosts.length, posts.length]);

  const tag = "Unlock your data potential.";
  const tagline = "Learn freely. Grow endlessly.";
  const imageUrl = LandingPageImage;

  return (
    <Box>
      <HeroSection tag={tag} tagline={tagline} imageUrl={imageUrl} />
      <ContentSection title="Browse Domains">
        {isDomainsLoading ? (
          <Loader />
        ) : (
          domains.map((domain) => (
            <Box
              key={domain.id}
              component={NavLink}
              to={`/posts/articles/${domain.name}/${domain.id}`}
              sx={{ textDecoration: "none", maxWidth: 350, width: "100%" }}
            >
              <DomainCard key={domain.id} domain={domain} />
            </Box>
          ))
        )}
      </ContentSection>
      <ContentSection title="Popular Posts">
        {isPopularPostsLoading ? (
          <Loader />
        ) : (
          popularPosts.map((popular, index) => (
            <Box
              key={popular.id}
              component={NavLink}
              to={`/post/${popular.id}`}
              sx={{ textDecoration: "none" }}
            >
              <FeaturePostCard
                index={index}
                key={popular.id}
                name={popular.name}
                date={popular.created_at}
                imageUrl={popular.image_url}
                description={popular.description}
              />
            </Box>
          ))
        )}
      </ContentSection>
    </Box>
  );
};

export default LandingPage;
