import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        marginTop: 5,
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        backgroundColor: "layout.main",
        // color: "layout.main",
        gap: "0.5rem",
        padding: "20px",
      }}
    >
      <Box
        sx={
          {
            // backgroundColor: "layout.main",
            // color: "inherit",
          }
        }
        id="Company"
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          About Us
        </Typography>
        <Typography variant="body2" sx={{ p: 2 }}>
          At KnowHub, We strive to bridge the gap between academics and
          corporate requirements in NextGen domains such as Big Data, Machine
          Learning, Artificial Intelligence and Automation. We envision to
          create an ecosystem that accelerates learning new age technologies by
          solving real world problems. We believe our courses would enhance
          technology skills of both experienced professionals and technology
          enthusiasts alike.
        </Typography>
      </Box>
      <Box
        sx={
          {
            // backgroundColor: "inherit",
            // color: "inherit",
          }
        }
        id="service"
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Knowhub vision
        </Typography>
        <Typography variant="body2" sx={{ p: 2 }}>
          Train students in cutting-edge technologies to ensure they are fully
          prepared for professional roles upon course completion.
        </Typography>
        <Typography variant="body2" sx={{ p: 2 }}>
          Periodically review training technologies and methods, setting up
          feedback channels for students and promptly addressing any reported
          issues with instructors.
        </Typography>
        <Typography variant="body2" sx={{ p: 2 }}>
          Establish rigorous guidelines for student selection and incorporate
          feedback from their managers after six months of employment to refine
          training strategies.
        </Typography>
      </Box>
      <Box
        sx={
          {
            // backgroundColor: "inherit",
            // color: "inherit",
          }
        }
        id="contact"
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Contact Us
        </Typography>
        <Typography
          sx={{ margin: "auto", p: 2, textAlign: "center" }}
          variant="body2"
        >
          theknowhub.com
        </Typography>
        <Typography
          sx={{ margin: "auto", p: 2, textAlign: "center" }}
          variant="body2"
        >
          admin@theknowhub.com
        </Typography>
        <Typography
          sx={{ margin: "auto", p: 2, textAlign: "center" }}
          variant="body2"
        >
          +91 99666 23912
        </Typography>
      </Box>
    </Box>
  );
}

export { Footer };
