import { getPopularPosts } from "../../../../ApiServices/auth.service";
import { PopularPostActionTypes } from "../constants/popular-posts-constants";

export const fetchPopularPostsRequest = () => ({
  type: PopularPostActionTypes.POPULAR_POSTS_REQUEST,
});

export const fetchPopularPostsSuccess = (popular_posts) => ({
  type: PopularPostActionTypes.POPULAR_POSTS_SUCCESS,
  payload: popular_posts,
});

export const fetchPopularPostsFailure = (error) => ({
  type: PopularPostActionTypes.POPULAR_POSTS_FAILURE,
  payload: error,
});

export const fetchPopularPosts = () => async (dispatch) => {
  dispatch(fetchPopularPostsRequest());
  try {
    const response = await getPopularPosts();
    dispatch(fetchPopularPostsSuccess(response.data));
  } catch (error) {
    dispatch(fetchPopularPostsFailure(error.message));
  }
};
