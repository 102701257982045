import React from "react";
import { Paper, Box, List, ListItem, ListItemText, Chip } from "@mui/material";
import { RichQuizContainer } from "../wrappers/RichQuizContainer";

const QuizResult = ({ quiz }) => (
  <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
    <Box variant="body1" sx={{ mb: 2 }}>
      <RichQuizContainer htmlContent={quiz.question} />
    </Box>
    <List>
      {quiz.options.map((option) => (
        <ListItem
          key={option.id}
          sx={{
            backgroundColor: option.is_answer
              ? "#d4edd9" // Green background for correct answers
              : option.is_submitted
              ? "#f9cccc" // Red background for incorrect but submitted answers
              : "inherit", // Default background for not submitted answers
            borderRadius: 10,
          }}
        >
          <ListItemText
            primary={
              <RichQuizContainer
                htmlContent={`<span class="reset_style">${option.text}</span>`}
              />
            }
          />
          {option.is_submitted && (
            <Chip
              label={
                option.is_answer
                  ? "Submitted & Correct"
                  : "Submitted & Incorrect"
              }
              color={option.is_answer ? "primary" : "secondary"}
              size="small"
              sx={{ ml: 2 }}
            />
          )}
        </ListItem>
      ))}
    </List>
  </Paper>
);

export default QuizResult;
