import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import styled from "@emotion/styled";
import { getPost } from "../../ApiServices/auth.service";
import { Loader } from "../../Components/common/Loader";
import { useParams } from "react-router-dom";
import { RichTextContainer } from "../../Components/wrappers/RichTextContainer";
import { useTheme } from "@emotion/react";

const ReadPostsPage = (props) => {
  const { post_id } = useParams();
  const theme = useTheme();

  const [postDetail, setPostDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await getPost(post_id);
        setPostDetail(response.data);
      } catch (error) {
        console.error("Error fetching post:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [post_id]);

  const ArticleContainer = styled(Box)`
    border-radius: 8px;
    margin: 0 auto;
    max-width: 850px;
    width: 100%;
    background-color: #fff;
  `;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <ArticleContainer>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: theme.palette.primary.main }}
        >
          {postDetail?.name}
        </Typography>
        <RichTextContainer htmlContent={postDetail?.body} />
      </ArticleContainer>
    </Box>
  );
};

export default ReadPostsPage;
