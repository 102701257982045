import { combineReducers } from "redux";
import { postReducer } from "../Pages/Articles-Page/redux/reducers/postsReducer";
import { quizReducer } from "../Pages/Quizzes-Page/redux/reducers/quizReducer";
import { domainReducer } from "../Pages/Landing-Page/redux/reducers/domainReducer";
import { popularPostReducer } from "../Pages/Landing-Page/redux/reducers/popularPostsReducer";
import { projectReducer } from "../Pages/Projects-Page/redux/reducers/projectReducer";

const rootReducer = combineReducers({
  posts: postReducer,
  quizzes: quizReducer,
  domains: domainReducer,
  popularPosts: popularPostReducer,
  projects: projectReducer,
});

export default rootReducer;
