import { DomainActionTypes } from "../constants/domains-constants";
import { getDomains } from "../../../../ApiServices/auth.service";

export const fetchDomainsRequest = () => ({
  type: DomainActionTypes.DOMAIN_REQUEST,
});

export const fetchDomainsSuccess = (domains) => ({
  type: DomainActionTypes.DOMAIN_SUCCESS,
  payload: domains,
});

export const fetchDomainsFailure = (error) => ({
  type: DomainActionTypes.DOMAIN_FAILURE,
  payload: error,
});

export const fetchAllDomains = () => async (dispatch) => {
  dispatch(fetchDomainsRequest());
  try {
    const response = await getDomains();
    dispatch(fetchDomainsSuccess(response.data));
  } catch (error) {
    dispatch(fetchDomainsFailure(error.message));
  }
};
