import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import NotFoundImg from "../../Assets/NotFoundImg.svg";
import BgLines from "../../Assets/BgLines.svg";
import { useTheme } from "@emotion/react";

const NotFound = () => {
  const theme = useTheme();
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: `url(${BgLines})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Oops...
          </Typography>
          <Typography variant="h6" gutterBottom>
            Page Not Found
          </Typography>
          <Typography variant="body1" gutterBottom>
            The page you are looking for doesn't exist. Please verify the URL
            you have entered.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/"
            sx={{
              display: "block",
              backgroundColor: "primary.main",
              marginY: 3,
              marginX: "auto",
              width: "35%",
              "&:hover": {
                backgroundColor: "inherit",
                color: "primary.main",
                outline: `1px solid ${theme.palette.primary.main}`,
              },
            }}
          >
            Go Back →
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src={NotFoundImg}
            alt="Page not found"
            style={{
              width: "100%",
              height: "auto",
              display: "block",
              margin: "0 auto",
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export { NotFound };
