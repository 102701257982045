import React, { useEffect, useState } from "react";
import ProjectImage from "../../Assets/ProjectImage1.svg";
import { Box } from "@mui/material";
import { HeroSection } from "../../Components/common/HeroSection";
import { ProblemCard } from "../../Components/cards/ProblemCard";
import { Loader } from "../../Components/common/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProjects } from "./redux/actions/projectActions";
import { getProject } from "../../ApiServices/auth.service";

const tag = "Code in Action:";
const tagline = "Complete a project!";
const imageUrl = ProjectImage;

const ProjectSelection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loadingProjectId, setLoadingProjectId] = useState(null);

  const { projects, isLoading: isProjectsLoading } = useSelector(
    (state) => state.projects
  );

  useEffect(() => {
    if (!projects.length) {
      dispatch(fetchAllProjects());
    }
  }, [dispatch, projects.length]);

  const onSolveClick = async (item) => {
    try {
      setLoadingProjectId(item.id);
      const res = await getProject(item.id);
      if (res.status === 200) {
        navigate(`/projects/${item.id}`, { state: res.data });
      } else {
        console.error("Error", res);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingProjectId(null);
    }
  };

  return (
    <Box>
      <HeroSection tag={tag} tagline={tagline} imageUrl={imageUrl} />
      {isProjectsLoading ? (
        <Loader />
      ) : (
        projects.map((item) => {
          return (
            <ProblemCard
              key={item.id}
              problemStatement={item.project_name}
              assignment={item.body}
              difficulty={item.level}
              onSolveClick={() => onSolveClick(item)}
              isButtonLoading={loadingProjectId === item.id}
            />
          );
        })
      )}
    </Box>
  );
};

export default ProjectSelection;
