import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";
import QuizResult from "../../Components/quiz/QuizResults";

const QuizResultPage = () => {
  const location = useLocation();
  const quizResult = location.state?.quizResult;
  const theme = useTheme();

  const correctAnswers = quizResult.reduce(
    (acc, quiz) =>
      acc +
      quiz.options.filter((option) => option.is_submitted && option.is_answer)
        .length,
    0
  );

  const totalQuestions = quizResult.length;

  return (
    <Box sx={{ p: 4, userSelect: "none" }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Quiz Results
      </Typography>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="h5"
          sx={{ my: 4, color: theme.palette.primary.main }}
        >
          Your Score is {correctAnswers} out of {totalQuestions}
        </Typography>
      </Box>
      {quizResult.map((quiz) => (
        <QuizResult key={quiz.id} quiz={quiz} />
      ))}
    </Box>
  );
};

export default QuizResultPage;
