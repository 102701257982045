import React from "react";
import { motion } from "framer-motion";
import styled from "@emotion/styled";

const RichTextContainer = ({ htmlContent }) => {
  const StyledContainer = styled(motion.div)`
    /* Base Styles */
    color: #333;
    // text-wrap: balance;

    /* Heading Styles */
    h1 {
      font-size: 2.8rem;
      font-weight: bold;
      font-family: monospace;
      // color: #1a237e;
      margin-bottom: 1.5rem;
      line-height: 1.2;
      border-bottom: 4px solid #1a237e;
      padding-bottom: 0.5rem;
    }

    /* Bold Styles */
    strong,
    b,
    u {
      font-size: 1.2rem;
      font-weight: 500;
      font-family: monospace;
      // color: #1a237e;
      margin-bottom: 1.5rem;
      line-height: 1.2;
      padding-bottom: 0.5rem;
      text-decoration: none;
    }

    /* Paragraph Styles */
    p {
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: 1.5rem;
      gap: 1rem;
      text-align: justify;
      font-family: Noto Sans Variable, sans-serif;
    }

    /* Unordered List Styles */
    ul {
      margin-bottom: 1.5rem;
      padding-left: 1rem;
    }

    li {
      margin: 0.75rem 4rem;
      padding-left: 1rem;
    }

    /* Link Styles */
    a {
      color: #1565c0;
      text-decoration: none;
      transition: transform 0.3s ease;
      &:hover {
        text-decoration: underline;
      }
    }

    /* Blockquote Styles */
    blockquote {
      border-left: 4px solid #0288d1;
      margin: 1.5rem 0;
      padding-left: 1.5rem;
      color: #555;
      font-style: italic;
    }

    /* Code Block Styles */
    pre {
      background-color: #f5f5f5;
      padding: 1.5rem;
      border-radius: 8px;
      overflow-x: auto;
      font-family: "Fira Code";
      font-size: 0.9rem;
      line-height: 1.4;
      color: #333;
      border: 1px solid #ddd;
      margin-bottom: 2rem;
    }

    /* Figure (Image) Styles */
    figure {
      margin: 2rem auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content !important;
    }

    @media (prefers-reduced-motion: no-preference) {
      img {
        display: block;
        object-fit: cover;
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        animation: fade-in linear forwards;
        animation-timeline: view();
        animation-range: entry;
      }

      @keyframes fade-in {
        from {
          scale: 0.9;
          opacity: 0;
        }
        to {
          scale: 1;
          opacity: 1;
        }
      }
    }
  `;

  return (
    <StyledContainer
      //   SCALE-UP
      // initial={{ scale: 0.95, opacity: 0 }}
      // animate={{ scale: 1, opacity: 1 }}
      // exit={{ scale: 0.9, opacity: 0 }}
      // transition={{ duration: 0.5 }}

      //   ROTATE-FROM-LEFT
      // initial={{ opacity: 0, rotate: -30 }}
      // animate={{ opacity: 1, rotate: 0 }}
      // exit={{ opacity: 0, rotate: 10 }}
      // transition={{ duration: 0.5 }}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export { RichTextContainer };
