import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Collapse,
} from "@mui/material";
import { NavLink } from "react-router-dom";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";

const Sidebar = ({
  drawerOpen,
  handleDrawerClose,
  learningPages,
  practicePages,
}) => {
  const theme = useTheme();
  const [openLearning, setOpenLearning] = useState(false);
  const [openPractice, setOpenPractice] = useState(false);

  const handleClickLearning = () => {
    setOpenLearning(!openLearning);
  };

  const handleClickPractice = () => {
    setOpenPractice(!openPractice);
  };

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={drawerOpen}
      onClose={handleDrawerClose}
    >
      <Box
        sx={{
          width: 240,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 1,
        }}
      >
        <Typography
          variant="h6"
          component={NavLink}
          to={"/"}
          noWrap
          sx={{
            textDecoration: "none",
            display: "flex",
            color: theme.palette.primary.main,
            cursor: "pointer",
            margin: "0 1%",
          }}
        >
          The Knowhub
        </Typography>
        <IconButton onClick={handleDrawerClose} sx={{ color: "primary.main" }}>
          {theme.direction === "rtl" ? (
            // <ChevronRightIcon />
            <CloseIcon />
          ) : (
            <CloseIcon />
            // <ChevronLeftIcon />
          )}
        </IconButton>
      </Box>
      <Divider />
      <List
        sx={{ width: "100%", maxWidth: 360, color: "primary.main" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        // subheader={
        //   <ListSubheader component="div" id="nested-list-subheader">
        //     Navigation
        //   </ListSubheader>
        // }
      >
        <ListItemButton onClick={handleClickLearning}>
          <ListItemText primary="Learning" />
          {openLearning ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openLearning} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {learningPages.map((page, index) => (
              <ListItem key={index} disablePadding sx={{ pl: 4 }}>
                <ListItemButton component={NavLink} to={page.slug}>
                  <ListItemText primary={page.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
        <ListItemButton onClick={handleClickPractice}>
          <ListItemText primary="Practice" />
          {openPractice ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openPractice} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {practicePages.map((page, index) => (
              <ListItem key={index} disablePadding sx={{ pl: 4 }}>
                <ListItemButton component={NavLink} to={page.slug}>
                  <ListItemText primary={page.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export { Sidebar };
