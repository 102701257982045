import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { register } from "../../ApiServices/auth.service";
import BgLines from "../../Assets/BgLines.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

const validationSchema = yup.object({
  firstname: yup.string().required("First name is required"),
  lastname: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const SignUpPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${BgLines})`,
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={3}
        component="main"
        sx={{
          maxWidth: "400px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box sx={{ width: "100%", margin: 3, padding: 3 }}>
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const { confirmPassword, ...dataToSend } = values; // Remove confirmPassword from the data to send
                const response = await register(dataToSend);
                toast.success("Registration successful");
                navigate("/login");
              } catch (error) {
                console.error("Registration error:", error);
                if (error.response) {
                  if (error.response.status === 400) {
                    toast.error("User with this email already exists");
                  } else if (error.response.status >= 500) {
                    toast.error("Server error, please try again later");
                  } else {
                    toast.error(
                      error.response.data?.detail || "An error occurred"
                    );
                  }
                } else if (error.request) {
                  toast.error(
                    "No response from server, please check your network connection"
                  );
                } else {
                  toast.error("An unexpected error occurred");
                }
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ errors, touched, handleChange, values, isSubmitting }) => (
              <Form>
                <TextField
                  fullWidth
                  id="firstname"
                  name="firstname"
                  label="First Name"
                  value={values.firstname}
                  onChange={handleChange}
                  error={touched.firstname && Boolean(errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  id="lastname"
                  name="lastname"
                  label="Last Name"
                  value={values.lastname}
                  onChange={handleChange}
                  error={touched.lastname && Boolean(errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => togglePasswordVisibility("password")}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={values.confirmPassword}
                  onChange={handleChange}
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            togglePasswordVisibility("confirmPassword")
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 2 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    display: "block",
                    marginTop: 3,
                    marginX: "auto",
                    width: "35%",
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "inherit",
                      color: "primary.main",
                      outline: `1px solid ${theme.palette.primary.main}`,
                    },
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={24}
                      sx={{ color: "primary.main" }}
                    />
                  ) : (
                    "Sign Up"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
        <Grid container>
          <Grid item xs>
            Already have an account?{" "}
            <Link to="/login" variant="body2">
              Login Here.
            </Link>
          </Grid>
        </Grid>
      </Paper>
      <ToastContainer />
    </Box>
  );
};

export default SignUpPage;
