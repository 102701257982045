// src/App.js
import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import components
import LayoutPage from "./Pages/Layout-Page/LayoutPage";
import LandingPage from "./Pages/Landing-Page/LandingPage";
import PostsListPage from "./Pages/Articles-Page/PostsListPage";
import ReadPostsPage from "./Pages/Articles-Page/ReadPostsPage";
import ProjectSelection from "./Pages/Projects-Page/ProjectSelection";
import ProjectPage from "./Pages/Projects-Page/ProjectPage";
import QuizSelectionPage from "./Pages/Quizzes-Page/QuizSelectionPage";
import QuizPage from "./Pages/Quizzes-Page/QuizPage";
import QuizResultPage from "./Pages/Quizzes-Page/QuizResultPage";
import LoginPage from "./Pages/Authentication-Page/LoginPage";
import SignUpPage from "./Pages/Authentication-Page/SignUpPage";
import { Loader } from "./Components/common/Loader";
import { NotFound } from "./Components/common/NotFound";
import PrivateRoute from "./Components/common/PrivateRoute";
import ChangePasswordPage from "./Pages/Authentication-Page/ChangePasswordPage";

function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<LayoutPage />}>
            <Route index element={<LandingPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="signup" element={<SignUpPage />} />
            <Route
              path="ChangePassword"
              element={<ChangePasswordPage />}
            ></Route>
            <Route
              path="posts/articles/:domainName?/:domainId?"
              element={
                // <PrivateRoute>
                <PostsListPage />
                // </PrivateRoute>
              }
            />
            <Route
              path="post/:post_id"
              element={
                // <PrivateRoute>
                <ReadPostsPage />
                // </PrivateRoute>
              }
            />
            <Route
              path="projects"
              element={
                <PrivateRoute>
                  <ProjectSelection />
                </PrivateRoute>
              }
            />
            <Route
              path="projects/:project-id"
              element={
                <PrivateRoute>
                  <ProjectPage />
                </PrivateRoute>
              }
            />
            <Route
              path="quiz-selection"
              element={
                <PrivateRoute>
                  <QuizSelectionPage />
                </PrivateRoute>
              }
            />
            <Route
              path="quiz/:language/:subject/:difficulty"
              element={
                <PrivateRoute>
                  <QuizPage />
                </PrivateRoute>
              }
            />
            <Route
              path="quiz-result"
              element={
                <PrivateRoute>
                  <QuizResultPage />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
