import { PopularPostActionTypes } from "../constants/popular-posts-constants";

const initialState = {
  popularPosts: [],
  isLoading: false,
  error: null,
};
export const popularPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case PopularPostActionTypes.POPULAR_POSTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case PopularPostActionTypes.POPULAR_POSTS_SUCCESS:
      return {
        ...state,
        popularPosts: action.payload,
        isLoading: false,
      };
    case PopularPostActionTypes.POPULAR_POSTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
