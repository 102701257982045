import { QuizActionTypes } from "../constants/quizzes-constants";

const initialState = {
  quizzes: [],
  isLoading: false,
  error: null,
};
export const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case QuizActionTypes.QUIZ_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case QuizActionTypes.QUIZ_SUCCESS:
      return {
        ...state,
        quizzes: action.payload,
        isLoading: false,
      };
    case QuizActionTypes.QUIZ_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
