import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";

const HeroSection = ({ tag, tagline, imageUrl }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      sx={{
        minHeight: { md: "67vh" },
        // backgroundImage: `url(${imageUrl})`,
        // borderImage: `linear-gradient(135deg, rgba(25, 117, 210, 0.5) 10%, rgba(255, 156, 75, 0.5) 50%) 1 fill`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
        // border: "1px solid red",
      }}
    >
      {/* Grid item for the text content */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          backdropFilter: "blur(1px)",
          borderRadius: 5,
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          <Typography
            variant="h3"
            sx={{ color: theme.palette.primary.main, mb: 2 }}
          >
            {tag}
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          <Typography
            variant="h4"
            sx={{ color: theme.palette.primary.main, mb: 4 }}
          >
            {tagline}
          </Typography>
        </motion.div>
      </Grid>

      {/* Grid item for the image */}
      <Grid item xs={12} md={6} sx={{ height: "100%" }}>
        <motion.img
          src={imageUrl}
          alt="Hero image"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "8px",
          }}
          initial={{ opacity: 0, x: 70 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3, ease: "easeInOut" }}
        />
      </Grid>
    </Grid>
  );
};

export { HeroSection };
