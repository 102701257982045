import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Paper,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import BgLines from "../../Assets/BgLines.svg";
import { ToastContainer, toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import { ResetPassword } from "../../ApiServices/auth.service";

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [formData, setFormData] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [showOldPassword, setOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = (field) => {
    if (field === "oldPassword") {
      setOldPassword(!showOldPassword);
    }
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
      isValid = false;
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const fields = [
    {
      name: "oldPassword",
      label: "Old Password",
      type: showOldPassword ? "text" : "password",
      adornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => togglePasswordVisibility("oldPassword")}
            edge="end"
          >
            {showOldPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    },
    {
      name: "password",
      label: "New Password",
      type: showPassword ? "text" : "password",
      adornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => togglePasswordVisibility("password")}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    },
    {
      name: "confirmPassword",
      label: "Confirm Password",
      type: showConfirmPassword ? "text" : "password",
      adornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => togglePasswordVisibility("confirmPassword")}
            edge="end"
          >
            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    },
  ];

  const handleSignUp = async (e) => {
    e.preventDefault();
    console.log(formData);
    if (validateForm()) {
      try {
        const response = await ResetPassword(formData);
        if (response.status === 200) {
          toast.success("Successful changed Password");
          navigate("/login");
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        const errorMessage =
          error.response?.data.message ||
          "An error occurred. Please try again later.";
        toast.error(errorMessage);
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${BgLines})`,
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={3}
        component="main"
        sx={{
          maxWidth: "400px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSignUp}
          sx={{ width: "100%", margin: 3, padding: 3 }}
        >
          {fields.map(({ name, label, type, adornment }) => (
            <TextField
              key={name}
              variant="outlined"
              fullWidth
              margin="normal"
              required
              label={label}
              type={type}
              name={name}
              value={formData[name]}
              onChange={handleChange}
              error={!!errors[name]}
              helperText={errors[name]}
              InputProps={{
                endAdornment: adornment,
              }}
            />
          ))}
          <Button
            type="submit"
            variant="contained"
            sx={{
              display: "block",
              marginTop: 3,
              marginX: "auto",
              width: "35%",
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "inherit",
                color: "primary.main",
                outline: `1px solid ${theme.palette.primary.main}`,
              },
            }}
          >
            Sign Up
          </Button>
        </Box>
        <Grid container>
          <Grid item xs>
            Already have an account?{" "}
            <Link to="/login" variant="body2">
              Login Here.
            </Link>
          </Grid>
        </Grid>
      </Paper>
      <ToastContainer />
    </Box>
  );
};

export default ChangePasswordPage;
