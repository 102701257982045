import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ContentSection = ({ title, children, isSeeMore = false, path }) => {
  return (
    <Box sx={{ width: "80%", margin: "auto" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" sx={{ margin: "5% 1%" }}>
          {title}
        </Typography>
        {isSeeMore && path && (
          <Typography
            component={Link}
            to={path}
            variant="body2"
            sx={{
              margin: "5% 1%",
              cursor: "pointer",
              textDecoration: "none",
              "&:hover": { textDecoration: "underline" },
              fontWeight: "bold",
            }}
          >
            See More
          </Typography>
        )}
      </Box>
      <Box
        elevation={0}
        sx={{
          padding: "2% 0",
          width: "100%",
          margin: "auto",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ContentSection;
